@import 'src/assets/scss/_variables.scss';


.api-tab {
  .list-table {
    border-right: 1px solid rgba(0, 0, 0, 0.146);
    height: calc(100vh - 195px) !important;
    overflow: auto !important;

    .data-list {
      border-bottom: 1px solid rgba(0, 0, 0, 0.146);

      .list-item {
        font-size: 13px;
        color: #263238;
        word-break: break-word;
      }
      .api-list-item {
        font-size: 13px;
        word-break: break-word;
      }

      .data-list-selected:active {
        background-color: #eaecef !important;
      }
    }
  }

  .query-paramet {
    resize: none !important;
  }

  .api-list-name {
    word-break: break-all;
  }

  .api-methods {
    padding-right: 24px !important;
  }
}

.custom-input {
  padding: 10px 15px !important;
  border-radius: 5px !important;
}

.custom-radio {
  display: flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;

  input {
    position: absolute;
    left: -9999px;
  }

  input:checked+span:before {
    box-shadow: inset 0 0 0 0.2375em $primary-color !important;
  }

  span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    font-size: 13px !important;
  }

  span:before {
    display: flex;
    flex-shrink: 0;
    content: '';
    background-color: #fff;
    width: 1.1em;
    height: 1.1em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em #1c3233;
  }
}

.action-close-btn {
  background-color: transparent !important;
  border: 0 !important;
  float: right !important;
  font-size: 25px !important;
}

.btn-refresh-user-access {
  position: absolute;
  top: -83px;
  right: 18px;
}

.user-access-role-scroller {
  height: calc(100vh - 195px);
  overflow: auto;

  .record-rules {
    border: 1px solid #ccc;
    border-radius: 15px;
  }

  .index-no {
    position: absolute;
    top: 0 !important;
    left: 10px !important;
  }

  .bg-04ffd5 {
    background-color: #1E88E5 !important;
  }

  @media (max-height:1120px) {
    .h-80vh {
      height: calc(100vh - 150px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .h-90vh {
      height: calc(100vh - 250px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.manage-table-section {
  .list-table {
    border-right: 1px solid rgba(0, 0, 0, 0.146);
    height: calc(100vh - 195px) !important;
    overflow-y: auto !important;
    .data-list {
      border-bottom: 1px solid rgba(0, 0, 0, 0.146);
      .list-item {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 991.98px) { 
    .list-table {
      border-right: 1px solid rgba(0, 0, 0, 0.146);
      height: auto !important;
      overflow-y: auto !important;
    }
   }
   .manage-table-accordion {
    height: calc(100vh - 195px);
    overflow: auto;
    .commanly-ui-accordian .form-group.col-lg-6{
      margin-bottom: 1rem !important;
  
    }
  }
  .form-check-label {
    cursor: pointer;
  }
  .table-attribute-dropdown{
    display: none !important;
  }
  .data-list:hover .table-attribute-dropdown{
    display: block !important;
    position: absolute !important;
    top: -8px !important;
    right: 0 !important;
  }
}

  .schema-titles{
    background: #ffffff;
    padding: 12px 16px;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);

}

.manage-show-table{
  height: calc(100vh - 300px);
  overflow: auto;
}